import {ECategoryCode} from 'constant/Place';
import {TPlaceItem} from 'ducks/place/types';
import {EHeadingStatus, TNowItem} from 'ducks/tnow/types';
import {TActionId} from './Log';
import {TYNUserSetting} from 'ducks/userInfo/types';
import {TVsmPublicTransportType} from 'ducks/userInteraction/types';
import {TTMapAdUnitMaterial, TTMapAdsLogType} from 'hooks/useTMapAds';

export type TSchemeParams = Array<{
  key: string;
  value: string | boolean | number;
}>;

export type TToggler = {
  label: string;
  params: any[];
  isOn: boolean;
  clickCode?: TActionId;
};

export enum EFilterType {
  TOGGLER,
}

export enum EAppPackage {
  IOS = 'com.SKTelecom.TMap',
  AOS = 'com.skt.tmap.ku',
  IOS_CODE = '431589174',
}

export enum EAppActivity {
  IOS = 'SearchWebViewController',
  AOS = 'TmapWebSearchActivity',
}

// https://tde.sktelecom.com/wiki/pages/viewpage.action?pageId=288145953
export enum ERPFlag {
  N_0000 = 0,
  N_1100 = 1,
  N_2010 = 2,
  N_3110 = 3,
  N_4001 = 4,
  N_5101 = 5,
  N_6011 = 6,
  N_7111 = 7,
  N_8000 = 8,
  N_9100 = 9,
  N_A010 = 10,
  N_B110 = 11,
  N_C001 = 12,
  N_D101 = 13,
  N_E011 = 14,
  N_F111 = 15,
  N_G000 = 16, // main
  N_H100 = 17,
  N_I010 = 18, // 지도지정 경유
  N_J110 = 19,
  N_K001 = 20,
  N_L101 = 21,
  N_M011 = 22,
  N_N111 = 23,
}

export enum ERPFlagCode {
  C_0000 = '0',
  C_1100 = '1',
  C_2010 = '2',
  C_3110 = '3',
  C_4001 = '4',
  C_5101 = '5',
  C_6011 = '6',
  C_7111 = '7',
  C_8000 = '8',
  C_9100 = '9',
  C_A010 = 'A',
  C_B110 = 'B',
  C_C001 = 'C',
  C_D101 = 'D',
  C_E011 = 'E',
  C_F111 = 'F',
  C_G000 = 'G', // main
  C_H100 = 'H',
  C_I010 = 'I', // 지도지정 경유
  C_J110 = 'J',
  C_K001 = 'K',
  C_L101 = 'L',
  C_M011 = 'M',
  C_N111 = 'N',
}

export enum EPlaceTag {
  VALET = 'valet',
  PARKING = 'parking',
  POPULAR = 'popular',
  TMAP_PARKING = 'tmap parking',
  DAY_OFF = 'day off',
  ACCOMMODATION_RESERVATION = 'accommodation',
  REFUEL_DISCOUNT_CARD = 'refuel discount card',
  CHEAPEST = 'cheapest',
  NEAREST = 'nearest',
  TRUCK_GAS_STATION = 'truck gas station',
  TRUCK_REST_AREA = 'truck rest area',
  DISCOUNT_COUPON = 'discount coupon',
  TMAP_PAYMENT = 'tmap payment',
  AUTO_PAYMENT = 'auto payment',
  DISCOUNT_PARKING_TICKET = 'discount parking ticket',
  TV_RESTAURANT = 'tv restaurant',

  EV_DC_COMBO = 'ev dc combo',
  EV_DC_CHADEMO = 'ev dc chademo',
  EV_AC3 = 'ev ac3',
  EV_AC_SLOW = 'ev ac slow',
  EV_CHARGER_OPEN = 'ev charger open',
  EV_SUPER_CHARGER = 'ev super charger',

  EV_CPO = 'EV_CPO',

  SA_TF_RECOMMEND = 'SA_TF_RECOMMEND',

  AD_POI = 'ad_poi',

  CAR_TUNING = 'car tuning',
  CAR_WASH = 'car wash',
  CONVENIENCE_STORE = 'convenience store',
  SELF_STATION = 'self station',
  ALLDAY_HOURS = '24 hours',

  TMAP_ONLY = 'tmap only',
  TMAP_WAITING = 'tmap waiting',
  TMAP_RESERVATION = 'tmap reservation',
  LOWEST = 'lowest',
  ECO = 'eco',

  AD_NOTICE = 'ad_notice',
}

export enum EPlaceCategoryType {
  SS_HOLIDAY_HSPT = 'SS_HOLIDAY_HSPT', // SEASONAL_HOLIDAY_HOSPITAL
  SS_PRE_POLLING_PLACE = 'SS_PRE_POLLING_PLACE',
  SS_POLLING_PLACE = 'SS_POLLING_PLACE',
  POPULAR = 'POPULAR',
  RANKING = 'RANKING',
  FOOD_ALL = 'FUDALL',
  FOOD_CAFE = 'CAFEALL',
  OIL_GAS_ALL = 'OILGAS',
  OIL_EV_ALL = 'OILEV',
  PARKING_LOT = 'PARKLOT',
  MART_ALL = 'MRTALL',
  BANK_ALL = 'BNKALL',
  CV_ALL = 'CVSALL',
  HSPTALL = 'HSPTALL',
  TOUR_ALL = 'TURALL',
  HOTL_ALL = 'HOTLALL',

  EMPTY = '',
}

export enum EOilType {
  ALL = 'OIL',
  GASOLINE = 'OILGAS',
  GASOLINE_PREMIUM = 'OILGASPM',
  DIESEL = 'OILDSL',
  LPG = 'OILLPG',
  EV = 'OILEV',
}

export enum EOilCompany {
  SK = 'SK',
  S_OIL = 'S_OIL',
  GS = 'GS',
  HYUNDAI = 'HYUNDAI',
  ETC = 'ETC',
  E1 = 'E1',
}

export enum EEVChargerOpen {
  SEMIPUBLIC = 'semipublic',
  PUBLIC = 'public',
}

export enum EEVChargerStatus {
  CHARGING_STANDBY = 'CHARGING_STANDBY',
  CHARGING = 'CHARGING',
  UNCONFIRMED = 'UNCONFIRMED',
}

export enum EEVChargerSpeed {
  SLOW = 'SLOW',
  FAST = 'FAST',
  SUPER_FAST = 'SUPER_FAST',
}

export enum EEVChargerType {
  DC_FAST = 'DC_CHADEMO', // DC차데모
  AC3 = 'AC_THREE_PHASE', // AC3상
  DC_COMBO = 'DC_COMBO', // DC콤보
  SLOW = 'AC_SINGLE_PHASE', // 완속
  SUPER_CHARGER = 'TESLA_SUPER_CHARGER', // 수퍼차저(테슬라)
  HYDROGEN = 'OILEVHYD', // 수소
}

export enum EEVChargeTypeCode {
  DC_COMBO = 'DC_COMBO',
  DC_CHADEMO = 'DC_CHADEMO',
  AC_THREE_PHASE = 'AC_THREE_PHASE',
  AC_SINGLE_PHASE = 'AC_SINGLE_PHASE',
}

export enum ECVType {
  CU = 'CVSCU',
  GS25 = 'CVSGS25',
  SEVEN_ELEVEN = 'CVSSVN11',
  EMART_24 = 'CVSEMART',
}

export enum EBankType {
  BANK = 'BNKBNK',
  ATM = 'BNKATM',
}

export enum EParkingLotType {
  PUBLIC = 'PARKLOTPUBLIC',
  TMAP_PARK = 'TMAP_PARK',
  PRV_PARK = 'PRV_PARK',
  PUB_PARK = 'PUB_PARK',
}

export enum EAccommodationType {
  HOTEL = 'HOTLHOTL', //호텔
  RESORT = 'HOTLRSORT', //콘도/리조트
  PENSION = 'HOTLPENS', //펜션
  CAMP = 'HOTLCAMP', //캠핑
  MOTEL = 'HOTLMOTL', //모텔
  ETC = 'HOTLETC', //기타
}

export enum EAccommodationProviderType {
  GOODCHOICE = 'GOODCHOICE',
  ONDA = 'ONDA',
}

export enum EEVStationType {
  CHARGE_EV = 'PI', // 차지비
  KOREA_POWER = 'KP', // 한국전력
  EVER_ON = 'EV', // 에버온
  GS_CONNECT = 'GN', // GS커넥트
  MINISTRY = 'ME', // 환경부
  KOREA_EV = 'HE', // 한국전기차충전서비스
  KLIN_ELEX = 'KL', // 클린일렉스
  JEJU_EV = 'JE', // 제주전기자동차서비스
  DAYEONG_CHAEVI = 'CV', // 대영채비
  ECAR_PLUG = 'EP', // 이카플러그
  TESLA = 'TESLA', // 테슬라는 별도의 변수로 확인
  VOLVO = 'VO',
  ETC = 'ETC', // 기타
  CHARZ_IN = 'EZ', // 차지인
  STAR_KOFF = 'SF', // 스타코프
  SK_ELECTLINK = 'ST', // SK일렉링크
  KEVIT = 'KE', // 한국전기차인프라기술
  LG_HELLOVISION = 'LH', // LG 헬로비전
  HUMAX = 'HM', // 휴맥스EV
  POWERCUBE = 'PW', // 파워큐브
  SPHAROSEV = 'IN', // 신세계아이앤씨
  SK_ENERGY = 'SK', // SK에너지
  SK_SIGNET = 'SG', // SK시그넷
}

export enum EGasStationType {
  NONE_POLE = '무폴',
  NH = 'NH-OIL',
  EX = 'EX-OIL',
  EX_GAS = 'EX',
  ETC = 'ETC',
  GENERAL = '일반',
  S_OIL = 'S-OIL',
  E1 = 'E1',
  OIL_BANK = 'OIL-BANK',
  GS = 'GS',
  NC = 'NC-OIL',
  SK = 'SK',
  ECONOMICAL = '알뜰',
}

export enum ECategoryGroup {
  DEFAULT = 'DEFAULT',
  RESTAURANT = 'RESTAURANT',
  GAS_CHARGING_STATION = 'GAS_CHARGING_STATION',
  EV_CHARGING_STATION = 'EV_CHARGING_STATION',
  PARKING = 'PARKING',
  MART = 'MART',
  ACCOMMODATION = 'ACCOMMODATION',
  REST_AREA = 'REST_AREA',
}

export enum ECollectionType {
  POI = 'poi',
  ADDRESS = 'address',
}

export enum EPoiType {
  POI = 'poi',
  ROAD = 'road',
  JIBUN = 'jibun',
}

export enum ERankingUpDownStatus {
  UP = 'UP',
  DOWN = 'DOWN',
  SAME = 'SAME',
  NEW = 'NEW',
}

export type TDrawerOptions = {
  centerHeight?: number;
  topAreaHeight?: number;
  topAreaPadding?: number;
  listHandleHeight?: number;
};

export const LAYER_ID = 'custom-layer-container';

export enum ESkeletonType {
  TNOW_HEADER,
  TNOW_LIST,
  PLACE_RESULT_LIST,
  CATEGORY_HEADER,
  CATEGORY_NO_FILTER_HEADER,
  SEARCH_QUICK_LINK,
  QUICK_LINK,
  SEARCH_HEADER,
  POI_MAIN_LIST,

  SEARCH_MAIN,
  SEARCH_PICK_ON_MAP,
  CALLOUT_POPUP,
  THEME_BOARD,
  THEME_BOARD_TAB,
  THEME_BOARD_RECOMMEND,
  SEARCH_RECOMMENDATION,
  SEARCH_RECOMMENDATION_OPEN,

  FAVORITE_LIST,

  SEARCH_RANKING_DROPDOWN,
}

export type TSKBesselCoordinate = {
  centerX: string | number;
  centerY: string | number;
  navX: string | number;
  navY: string | number;
};

export type TWgs84Coordinate = {
  wgs84CenterX: number;
  wgs84CenterY: number;
  wgs84NavX: number;
  wgs84NavY: number;
};

export type TReverseGeocoding = {
  regionName1: string;
  regionName2: string;
  regionName3: string;
  regionName4: string;
  bunji: string;
  roadName: string;
  roadBunji: string;
  buildingName: string;
  centerX: string;
  centerY: string;
  currentArea: string;
  jibunPkey: string;
  roadPkey: string;
  jibunAddrCoordination: TSKBesselCoordinate & TWgs84Coordinate;
  roadAddrCoordination: TSKBesselCoordinate & TWgs84Coordinate;
  legalDongCode: string;
  areaDepth1Code: string;
  areaDepth2Code: string;
  areaDepth3Code: string;
};

type TFoundAreaInfo = {
  defaultRegion1Name: string;
  region2NameList: string[];
};

export type TTplaceReverseGeocoding = {
  regionFullCode: string;
  region1FullCode: string;
  region2FullCode: string;
  region3FullCode: string;
  region1Code: string;
  region2Code: string;
  region3Code: string;
  region1Name: string;
  region2Name: string;
  region3Name: string;
  foundAreaInfo: TFoundAreaInfo;
};

export enum EReverseGeocodingError {
  NO_DATA = 'NO_DATA',
  GENERAL = 'GENERAL',
}

export type TPoiBasicInfo = {
  pkey: string;
  poiId: number;
  navSeq: number;
  poiNameSvc: string;
  centerX: number;
  centerY: number;
  navX: number;
  navY: number;
  wgs84CenterX: number;
  wgs84CenterY: number;
  wgs84NavX: number;
  wgs84NavY: number;
  fullJibunAddr: string;
  fullRoadAddr: string;
  phones: Array<{
    phoneType: string;
    phoneNumber: string;
  }>;
};

export type TPoiGroupSub = {
  pkey: string;
  poiId: string | number;
  seq: number;
  navSeq: number;
  poiName: string;
  categoryGroup: ECategoryGroup;
  rpFlag: number;
  isTmapPayment: Nullable<boolean>;
  isDiscountCoupon: Nullable<boolean>;
  realTimeParkingLotInfo: Nullable<TRealTimeParkingLotInfo>;
  special: Partial<TSpecialData>;
  tag: Partial<TPlaceTagItem>;
} & TSKBesselCoordinate &
  TWgs84Coordinate & {
    placeTags: EPlaceTag[];
    listId: string;
    listName: string;
  };

export type TPoiImageInfo<T = string> = {
  path: string;
  source: Nullable<T>;
  url?: string;
  url2?: string;
};

export type TAccommodationInfo = {
  priceForNights: Nullable<number>;
  priceForRent: Nullable<number>;
  accommodationPropertyId: Nullable<string>;
  provider: Nullable<EAccommodationProviderType>;
  dayUseMinPrice: Nullable<number>;
  dayUseMaxPrice: Nullable<number>;
  roomMinPrice: Nullable<number>;
  roomMaxPrice: Nullable<number>;
  isLowestPrice: Nullable<boolean>;
  isTmapOnlyAndLowestPrice: Nullable<boolean>;
};

export type TCatchUnit = {
  name: string;
  time: Nullable<number>;
  count: number;
  isOpen: boolean;
  isTakeOut: boolean;
};

export type TCatchTableWaitingResponse = {
  cpPoiId: string;
  isAvailableReservation: boolean;
  isAvailableWaiting: boolean;
  isAvailableOnlineWaiting: boolean;
  onlineWaitingDisableReason: string;
  unit: TCatchUnit;
};

export type TEVImageResource = {
  id: string;
  icon: string;
  activeMarker: string;
  poiMarker: string;
  label: string;
};

export type TEvChargerInfo = {
  superFastChargerCount: number;
  fastAvailableCount: number;
  superFastAvailableCount: number;
  slowAvailableCount: number;
  fastChargerCount: number;
  slowChargerCount: number;
  operatorIds: string[];
  lastChargingMinuteAgo: Nullable<number>;
  chargerTypes: Nullable<EEVChargeTypeCode[]>;
  operatorId: Nullable<string>;
  operatorName: Nullable<string>;
  operatorImageUrl: Nullable<string>;
};

export type TGasStationInfo = {
  premiumGasolinePrice: number;
  gasolinePrice: number;
  dieselPrice: number;
  lpgPrice: number;
  oilBrand: string;
};

export type TParkingFeeInfo = {
  parkFee: number;
  parkFeeBaseTime: number;
  parkFeeAdd: number;
  parkFeeAddTime: number;
};

export enum EParkingStatus {
  GOOD = '여유',
  BUSY = '혼잡',
  FULL = '만차',
}

export type TRealTimeParkingLotInfo = {
  parkingAbleNumber: number;
  parkingNumber: number;
  parkingStatus: EParkingStatus;
};

export type TTmapParkingTicket = {
  ticketId: string;
  ticketType: string;
  ticketName: string;
  ticketFee: number;
  ticketOriginFee: number;
  discountRate: number;
  isFreeEntryAndExit: boolean;
};

export type TTmapParkingLot = {
  tickets: TTmapParkingTicket[];
};

type TRankingUpDownInfo = {
  status: ERankingUpDownStatus;
  gapNumber: number;
};

export type TAdvertiseInfo = {
  isPoiAdvertiseYn: boolean;
  advertiseMaterials: TTMapAdUnitMaterial[];
  advertiseTrackingUrls: Array<{
    key: TTMapAdsLogType;
    value: string;
  }>;
};

export type TSpecialData = {
  accommodationInfo: Nullable<TAccommodationInfo>;
  businessHours: Nullable<string>;
  evChargerInfo: Nullable<TEvChargerInfo>;
  gasStationInfo: Nullable<TGasStationInfo>;
  offDay: Nullable<string>;
  parkingFeeInfo: Nullable<TParkingFeeInfo>;
  realTimeParkingLotInfo: Nullable<TRealTimeParkingLotInfo>;
  isTesla: Nullable<boolean>;
  tmapParkingLot: Nullable<TTmapParkingLot>;
  businessHourStatus: Nullable<EBusinessHourStatus>;
  reviewCount: Nullable<number>;
  avgStar: Nullable<number>;
  catchTableWaitingResponse: Nullable<TCatchTableWaitingResponse>;
  rankingUpDownInfo: Nullable<TRankingUpDownInfo>;
  advertiseInfo: TAdvertiseInfo;
};

export type TPlaceTagItem = {
  isParking: boolean;
  isPopular: boolean;
  isTmapPark: boolean;
  isDayOff: boolean;
  isTmapWaiting: boolean;
  isTmapReservation: boolean;
  isAccommodation: boolean;
  isRefuelDiscountCard: boolean;
  cheapestPoi: boolean;
  nearestPoi: boolean;
  isTruckGasStation: Nullable<boolean>;
  isTruckRestArea: Nullable<boolean>;
  isTmapPayment: Nullable<boolean>;
  isDiscountCoupon: Nullable<boolean>;
  evChargerOpen: Nullable<EEVChargerOpen>;
  isAutoPayment: Nullable<boolean>;
  isDiscountTicket: Nullable<boolean>;
  isRecommendAds: Nullable<boolean>;
  isValetParking: Nullable<boolean>;
  isAdvertisement: Nullable<boolean>;
  isTmapBike: Nullable<boolean>;
  isCarTuning: Nullable<boolean>;
  isCarWash: Nullable<boolean>;
  isConvenienceStore: Nullable<boolean>;
  isSelfStation: Nullable<boolean>;
  is24Hours: Nullable<boolean>;
  isEco: Nullable<boolean>;
  isTVrestaurant: Nullable<boolean>;
  localFoodHotPlaceYn: Nullable<boolean>;
};

export type TPoiItem<T = TNowItem | TPlaceItem> = {
  isPopular?: boolean;
  isParking?: boolean;
  headingForTick?: EHeadingStatus;
  categoryGroup?: ECategoryGroup;
  special?: TSpecialData;
  navSeq?: number;
  wgs84NavX?: number;
  wgs84NavY?: number;
  displayPhoneNumber?: string;
  rpFlag?: number;
  holidayBusinessDays?: string[];
} & T;

export type TSearchPoi = {
  pkey: string;
  poiId: string;
  navSeq: number;
  poiName: string;
  addressNameDepth2?: string;
  svcCategoryName: string;
  categoryGroup: ECategoryGroup;
  rpFlag: number;
  displayPhoneNumber: string;
  fullRoadAddr: string;
  fullJibunAddr: string;
  distance: number;
  userRealDistance: number;
  groupSubList: TPoiGroupSub[];
  special: TSpecialData;
  tag: TPlaceTagItem;
  collectionType: ECollectionType;
  poiType: EPoiType;
  destinationCount: number;
  headingForScore: number;
  images: string[];
  imageInfo: TPoiImageInfo[];
  isUnderground: boolean;
  isExposureScore: boolean;
  isNavPoint: Nullable<boolean>;
  holidayBusinessDays: string[];
  mainCategory: ECategoryCode | string;
  markerCategory: Nullable<string>;
  markerCategories: string[];
  stationType: TVsmPublicTransportType;
  stationExternalId: string; // 아로 id
  stationInternalId: string; // vsm stationId
} & TSKBesselCoordinate &
  TWgs84Coordinate & {
    placeTags: EPlaceTag[];
    listId: string;
    listName: string;
  };

export type TPoiDetailParam = {
  poiId?: string | number;
  navX?: string | number;
  navY?: string | number;
  pkey?: string | number;
  navSeq?: string | number;
  mapX?: string | number;
  mapY?: string | number;
  extra?: EAppExtraCode;
  reqMode?: EAppRequestMode;
  reqType?: EAppRequestTypeNew;
  sendCar?: TYNUserSetting;
  favorite?: TYNUserSetting;
  tailParam?: string;
  popupId?: string;
  reserve?: boolean;
  wait?: boolean;
  favId?: string;
};

export type TFavoriteItem = {
  navX?: Nullable<string | number>;
  navY?: Nullable<string | number>;
  pkey?: Nullable<string | number>;
  poiId?: Nullable<string | number>;
  stationId?: Nullable<string | number>;
};

export enum EAppRequestMode {
  MAIN = '1100', // 메인에서 진입 (DEFALT)
  ROUTE = '1110', // 경로요약에서 진입
  DRIVING = '1120', //길안내 중 진입
  WIDGET = '1130', // 위젯에서 진입
  FAVORITE = '1140', // 즐겨찾기 추가에서 진입
  REPORT = '1210', // 제보하기에서 진입
  PUBLIC_TRANSPORT = '1150', // 대중교통에서 진입
  PUBLIC_TRANSPORT_ROUTE = '1160', // 대중교통 길찾기에서 진입
  WALK_ROUTE = '1170', // 대중교통 도보 길찾기에서 진입
  DRIVING_COMPLETE = '1180', // 주행완료에서 진입
  EVENT_PAGE = '1500', // 이벤트 페이지에서 진입
  TPLACE_HOME = '1600', // 티플레이스 홈에서 진입
  SAVE_DETAIL_PAGE = '1700', // 저장 상세 페이지에서 진입
  OTHER_SERVICE = '10000', // 다른 서비스에서 진입
}

export enum EAppExtraCode {
  SETTING_GO_HOME = '110', //집 등록
  SETTING_GO_OFFICE = '111', //회사 등록
  SEARCH_T_MAP = '112', // 검색
  SETTING_DEFAULT = '115', // 지도 - 콜아웃팝업 (상세용)
  ROUTE_SEARCH_T_MAP = '116', // 경로요약 -> 검색
  PHOTO_SEARCH = '117', // 사용 안함
  SETTING_FAVORITE = '118', //즐겨찾기 등록
  ROUTE_ITEM_DETAIL = '119', // 경로요약 ->출/경/도착선택->상세 클릭
  ANOTHER_TIME = '120', // 다른시간
}

export enum EAppRequestTypeNew {
  NO_DATA = 0,
  DESTINATION = 100,
  MIN_WAYPOINT = 200,
  MAX_WAYPOINT = 399,
  DEPARTURE = 400,
  ALL_DATA = 500,
}

export enum EBusinessHourStatus {
  TODAY_OFF = 'TODAY_OFF', //오늘휴무
  BEFORE = 'BEFORE', //영업전
  OPEN = 'OPEN', // 영업중
  CLOSED = 'CLOSED', // 영업종료
  BREAK_TIME = 'BREAK_TIME', // 브레이크 타임
  CLOSING_SOON = 'CLOSING_SOON', // 영업종료임박
}

export type TShareData = {
  title: string;
  text: string;
  url: string;
};

export enum EPlaceFeature {
  CAR_WASH = 'CAR_WASH',
  CAR_TUNING = 'CAR_TUNING',
  CONVENIENCE_STORE = 'CONVENIENCE_STORE',
  SELF_STATION = 'SELFSTATION',
  ALLDAY_HOURS = '24_HOURS',
  TMAP_RESERVATION = 'TMAP_RESERVATION',
  TMAP_WAITING = 'TMAP_WAITING',
}

export enum EPlaceFeatureOperator {
  OR = 'OR', // 기본값
  AND = 'AND',
}

export enum EDetailButtonType {
  DEPARTURE = 'DEP',
  VIA = 'VIA',
  DESTINATION = 'DEST',
  NONE = 'NONE',
  CONFIRM = 'CONFIRM',
}
