import {useCallback, useEffect, useMemo, useState} from 'react';
import findIndex from 'lodash/findIndex';
import {TDiscoveryItem} from 'ducks/tplacehome/types';
import {setComma} from 'utils/formatter';
import usePlaceHome from 'hooks/usePlaceHome';
import TmdsTag from 'components/@tmds/TmdsTag';
import Thumbnail from 'components/Thumbnail';
import {getImageUrl} from 'utils/url';
import {EImageSize} from 'types/Image';
import DiscoveryPoiItemInfo from './DiscoveryPoiItemInfo';
import {ReactComponent as IcStar} from 'resource/images/ic_star_stroke.svg';
import {ReactComponent as IcStarFill} from 'resource/images/ic_star_stroke_fill.svg';
import s from 'styles/components/tplacehome/TPlaceDiscoveryColumnPoiItem.module.scss';

type TProps = {
  data: TDiscoveryItem;
  thumbHeight: number;
  isLowestPrice?: boolean;
  isFavorite?: boolean;
  onClickDetail?: () => void;
  thumbnailSize?: EImageSize;
};

const DiscoveryColumnPoiItem = ({
  data,
  thumbHeight,
  isLowestPrice,
  onClickDetail,
  thumbnailSize,
  ...props
}: TProps) => {
  const {poiName, imageUrl, reviewDto, svcCategoryName, distance, accommodationInfo} = data;
  const {reviewCount, reviewGrade} = reviewDto;
  const [isFavorite, setIsFavorite] = useState(false);

  const placehomeHook = usePlaceHome();
  const handleToggleFavorite = useCallback(() => {
    const wrap = document.querySelector('[data-discovery-wrap]');
    const itemEl = wrap?.querySelector(`[data-discovery-item="${data.poiId}"]`);
    const itemIndex = findIndex(wrap?.querySelectorAll('[data-discovery-item]'), itemEl);

    placehomeHook.toggleFavorite(data, {itemIndex}).then((isOn) => {
      setIsFavorite(isOn ? true : false);
    });
  }, [data, placehomeHook]);

  const handleMoveToDetail = useCallback(() => {
    onClickDetail?.();
    placehomeHook.openPoiDetail(data);
  }, [data, onClickDetail, placehomeHook]);

  const lowestAccommodation = useMemo(() => {
    if (!isLowestPrice || !accommodationInfo?.onda) {
      return null;
    }

    const info = accommodationInfo?.onda;
    const price = `${setComma(info.price)}원 ~`;
    if (info.isTmapSpecialPrice) {
      return {
        label: '티맵단독',
        price,
        bg: 'tmobiBlue400',
      };
    } else if (info.isLowestPrice) {
      return {
        label: '최저가보장',
        price,
        bg: 'tmobiPink400',
      };
    } else {
      return null;
    }
  }, [accommodationInfo?.onda, isLowestPrice]);

  useEffect(() => {
    setIsFavorite(!!props.isFavorite);
  }, [props.isFavorite]);

  return (
    <div className={s.wrap} data-discovery-item={data.poiId}>
      <button className={s.content} onClick={handleMoveToDetail}>
        <div className={s.thumb} style={{height: thumbHeight}}>
          <Thumbnail
            imgSrc={getImageUrl(imageUrl, thumbnailSize)}
            alt={`${poiName} 이미지`}
            className={s.img}
          />
          {lowestAccommodation && (
            <TmdsTag
              label={lowestAccommodation.label}
              backgroundColor={lowestAccommodation.bg}
              color={'wbWhite'}
              fontSize={12}
              className={s.tag}
              filled
            />
          )}
        </div>
        <div className={s.info}>
          <em className={s.name}>{poiName}</em>
          <span className={s.category}>{svcCategoryName}</span>
          {lowestAccommodation ? (
            <em className={s.price}>{lowestAccommodation.price}</em>
          ) : (
            <DiscoveryPoiItemInfo
              starPoint={reviewGrade}
              reviewCount={reviewCount}
              distance={distance}
            />
          )}
        </div>
      </button>
      <button
        type="button"
        className={s.favorite}
        style={{top: `${thumbHeight - 32}px`}}
        onClick={handleToggleFavorite}
      >
        {isFavorite ? <IcStarFill /> : <IcStar />}
      </button>
    </div>
  );
};

export default DiscoveryColumnPoiItem;
