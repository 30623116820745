import {IcoLineBetween} from 'components/@tmds/icons/IcoLineBetween';
import {IcoStarFilled} from 'components/@tmds/icons/IcoStarFilled';
import classNames from 'classnames';
import {setComma} from 'utils/formatter';
import {TCatchUnit, TSpecialData} from 'types/App';
import {useCallback, useEffect, useMemo, useState} from 'react';
import useInterval from 'hooks/useInterval';

import s from 'styles/components/ranking/RankingPoiInfo.module.scss';

const BusinessHours = ({label, color}) => (
  <div className={s.operation} data-tmds-color={color} data-color={color}>
    {label}
  </div>
);

const Rating = ({avgStar, reviewCount}) => (
  <div className={s.rating_wrap}>
    {avgStar ? (
      <>
        <IcoStarFilled width={16} height={16} color="tmobiTeal400" />
        <span className={s.rating}>{avgStar.toFixed(1)}</span>
        <span className={s.count}>({setComma(reviewCount)})</span>
      </>
    ) : (
      <span className={s.count}>
        리뷰 <span className={s.number}>{setComma(reviewCount)}</span>
      </span>
    )}
  </div>
);

type TItem = {
  content: React.ReactNode;
  id: number;
};

type TWaitingProps = {
  headingScore: string;
  unit?: TCatchUnit;
};

const ROLLING_INTERVAL = 3000;

const Waiting = ({headingScore, unit}: TWaitingProps) => {
  const [trigger, setTrigger] = useState(false);
  const [rollingList, setRollingList] = useState<TItem[]>([]);

  const contents = useMemo(() => {
    const items = [
      headingScore && (
        <div key="heading" className={classNames(s.tnow_count, s.item)}>
          <span className={s.score}>{headingScore}</span>대 가는중
        </div>
      ),
      unit && unit.count > 0 && (
        <div key="waiting" className={classNames(s.waiting, s.item)}>
          <span>현재 웨이팅</span>
          <span className={s.score}>{unit.count}</span>팀
        </div>
      ),
    ].filter(Boolean);

    return items.map((item, index) => ({content: item, id: index}));
  }, [headingScore, unit]);

  const interval = useInterval(() => {
    setTrigger(true);
  }, ROLLING_INTERVAL);

  useEffect(() => {
    setRollingList(contents);

    if (contents.length > 1) {
      interval.start();
    }

    return () => {
      interval.stop();
    };
  }, []);

  const handleTransitionEnd = useCallback((id: number) => {
    if (id !== 0) {
      return;
    }

    setTrigger(false);
    setRollingList((prev) => {
      const [first, ...rest] = prev;
      return [...rest, first];
    });
  }, []);

  if (rollingList.length === 0) {
    return null;
  }

  return (
    <div className={s.waiting_wrap}>
      <div className={s.rolling_wrap}>
        {rollingList.map(({content, id}) => (
          <div
            key={id}
            className={classNames(s.content, {
              [s.is_move]: trigger,
            })}
            onTransitionEnd={() => handleTransitionEnd(id)}
          >
            {content}
          </div>
        ))}
      </div>
    </div>
  );
};

type TProps = {
  businessHoursInfo: Nullable<[string, string]>;
  special: TSpecialData;
  headingScore: string;
  unit?: TCatchUnit;
  className?: string;
};

const RankingPoiInfo = ({businessHoursInfo, special, headingScore, unit, className}: TProps) => {
  const showBusinessHours = !!businessHoursInfo;
  const showRating = !!special?.reviewCount;
  const showWaiting = !!(headingScore || unit?.count);

  const showFirstDivider = showBusinessHours && showRating;
  const showSecondDivider = (showBusinessHours || showRating) && showWaiting;

  const [businessHourLabel, businessHourColor] = businessHoursInfo || [];

  if (!showBusinessHours && !showRating && !showWaiting) {
    return null;
  }

  return (
    <div className={classNames(s.info_wrap, className)}>
      {showBusinessHours && <BusinessHours label={businessHourLabel} color={businessHourColor} />}

      {showFirstDivider && <IcoLineBetween width={16} height={16} color="gray200" />}

      {showRating && <Rating avgStar={special.avgStar} reviewCount={special.reviewCount} />}

      {showSecondDivider && <IcoLineBetween width={16} height={16} color="gray200" />}

      {showWaiting && <Waiting headingScore={headingScore} unit={unit} />}
    </div>
  );
};
export default RankingPoiInfo;
